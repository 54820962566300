import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";
import { useAuthStore } from "@/store/AuthStore";
import UserService from "./UserService";

const AuthService = {
  async login(username: string, password: string): Promise<number> {
    const loginData: AxiosRequestConfig = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: process.env.VUE_APP_AUTH_BASE_URL + "/api/login_check",
      data: {
        username: username,
        password: password,
      },
    };

    try {
      const response = await ApiService.customRequest(loginData);
      const { token, refresh_token } = response.data;
      const authStore = useAuthStore();
      authStore.login(token, refresh_token);
      ApiService.setHeader(token);

      const user = await UserService.getProfile();
      authStore.setUser(user);

      return response.status;
    } catch (error: any) {
      return error.response?.status;
    }
  },
  async logout() {
    const authStore = useAuthStore();
    try {
      await ApiService.get(
        process.env.VUE_APP_AUTH_BASE_URL + "/api/token/revoke"
      );
    } catch (error) {
      console.error(error);
    }
    authStore.logout();
    return true;
  },
  async refreshToken(refreshToken: string) {
    const refreshTokenData: AxiosRequestConfig = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: process.env.VUE_APP_AUTH_BASE_URL + "/api/token/refresh",
      data: {
        refresh_token: refreshToken,
      },
    };
    const response = await ApiService.customRequest(refreshTokenData);
    const { token, refresh_token } = response.data;

    const authStore = useAuthStore();
    authStore.login(token, refresh_token);
    ApiService.setHeader(token);
  },
};

export default AuthService;
